import { useEffect, useState } from 'react';
import { RfidCardCreationValidityController } from 'fragments/rfidcards/fragments/rfid-card-creation-validity/interfaces';
import { useForm } from 'antd/lib/form/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAPIRfidcard } from 'services/rfidcards/api-rfidcards.service';
import { onCancelAddCard, onUpdateCard } from 'mobile/rfid/rfid-functions';
import { OnUpdateCardInput } from 'mobile/rfid/rfid-interfaces';
import { useDowFunctions } from 'tools/dow-functions';
import { MainError } from 'services/dtos/errors-dto';

export const useRfidCardCreationValidityController = (
    onModalLabel: () => void,
    onModalOverwriteMsg: () => void,
    rfidCardService = useAPIRfidcard(),
): /* <--Dependency Injections  like services hooks */
RfidCardCreationValidityController => {
    /* State */
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [form] = useForm();
    const { getDaysBytesFromDateRange } = useDowFunctions();

    // Query params
    const deviceId = searchParams.get('device_id')?.toLowerCase() || '';
    const accessToken = searchParams.get('access_token') || '';
    const cyclicRfidConfigEnabled = searchParams.get('cyclic_rfid_config_enabled') === 'true';
    const cardId = searchParams.get('card_id') || '';
    const cardNumber = searchParams.get('card_number') || '';
    const overwritten = searchParams.get('overwritten') === 'true';
    const labelParams = searchParams.get('label') || '';

    // loaders
    const [isLoading, setIsLoading] = useState(false);

    // states
    const [label, setLabel] = useState<string>(labelParams);
    const [labelError, setLabelError] = useState<boolean>(false);
    const [dowBytewise, setDowBytewise] = useState<number>(127);
    const [disabledDays, setDisabledDays] = useState<number[]>([]);
    const [dateRange, setDateRange] = useState<{ from: string; to: string }>({ from: '', to: '' });
    const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({ from: '00:00', to: '23:59' });

    const [isChecking, setIsChecking] = useState(false);

    /* Listeners */
    useEffect(() => {
        //no access token || no device id || ni card number, ni card id || si card id pero no label
        if (!accessToken || !deviceId || (!cardNumber && !cardId) || (cardId && !label)) {
            navigate('/mobile/404');
        }
        overwritten && onModalOverwriteMsg();
    }, []);

    useEffect(() => {
        setDisabledDays(getDaysBytesFromDateRange(dateRange.from, dateRange.to));
    }, [dateRange]);

    /* View Events */

    const onLabelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLabelError(false);
        setLabel(e.target.value);
        if (e.target.value.length > 2) {
            checkLabel(e.target.value);
        }
    };

    const onBackButtonPressed = () => {
        onCancelAddCard({ v: 2, data: {} });
        return;
    };

    const onCreateRfidCardFormSubmit = () => {
        setIsLoading(true);
        const data: OnUpdateCardInput = {
            dow: dowBytewise,
            nbf_date: dateRange.from + 'T00:00:00Z',
            expires_at_date: dateRange.to + 'T00:00:00Z',
            nbf_time: timeRange.from + ':00',
            expires_at_time: timeRange.to + ':00',
        };

        if (cardId) {
            // opcion A
            data.card_id = cardId;
        } else {
            // opcion B
            data.card_number = cardNumber;
            data.label = label;
        }

        onUpdateCard({
            v: 2,
            data,
        });
    };

    /* Private Methods */

    const checkLabel = (labelToCheck: string) => {
        setIsChecking(true);
        rfidCardService
            .checkDuplicatedRfidCard({ deviceId, label: labelToCheck, accessToken })
            .then(() => {
                setLabelError(false);
            })
            .catch((err) => {
                const error = err as MainError;
                if (error.code === 409) {
                    switch (error.msg) {
                        case 'label':
                            setLabelError(true);
                            onModalLabel();
                            break;
                        case 'role':
                            navigate('/mobile/404');
                            break;
                        default:
                            navigate('/mobile/404');
                            break;
                    }
                }
            })
            .finally(() => {
                setIsChecking(false);
            });
    };

    // Return state and events
    return {
        label,
        isLoading,
        dateRange,
        timeRange,
        dowBytewise,
        disabledDays,
        form,
        labelParams,
        cyclicRfidConfigEnabled,
        labelError,
        isChecking,
        onCreateRfidCardFormSubmit,
        onBackButtonPressed,
        setDowBytewise,
        setTimeRange,
        setDateRange,
        onLabelInputChange,
    };
};
