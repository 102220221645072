import { useEffect, useState } from 'react';
import { SiteList, SiteListController } from 'fragments/sites/fragments/site-list/interfaces';
import { useAPISites } from 'services/sites/api-sites.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAPILocks } from 'services/locks/api-locks.service';
import { LockDto, LockDtoMinimal } from 'services/locks/locks.service';
import { LockDtoSimple, LockList } from 'fragments/sites/fragments/lock-list/interfaces';
import { PaginationRequest } from 'global/interfaces';
import { useSitesContext } from 'fragments/sites/context/sites.context';
import { SiteDtoSimple } from 'services/sites/sites.service';
import { onOpenLockAdministration } from 'mobile/locks/locks-functions';

let searchStr = '';

const emptyList = {
    list: [],
    totalItems: 0,
    pageSize: 15,
    page: 0,
};

export const useSiteListController = (
    sitesService = useAPISites(),
    lockService = useAPILocks(),
): SiteListController => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { accessToken, setAccessToken, setSelectedSite } = useSitesContext();

    /* State */
    // loaders
    const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false);
    const [isLoadingLocksResults, setIsLoadingLocksResults] = useState<boolean>(false);
    const [isLoadingSitesResults, setIsLoadingSitesResults] = useState<boolean>(false);

    // estados
    const [focus, setFocus] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [panel, setPanel] = useState<number>(0);

    // listas
    const [sites, setSites] = useState<SiteList>(emptyList);
    const [sitesResults, setSitesResults] = useState<SiteList>(emptyList);
    const [locksResults, setLocksResults] = useState<LockList>(emptyList);

    const access_token = searchParams.get('access_token') || '';

    /* Listeners */
    useEffect(() => {
        if (accessToken) {
            setAccessToken(access_token);
            getSitesList(access_token, { page: sites.page, pageSize: sites.pageSize });
        } else {
            navigate('/mobile/404');
        }
    }, []);

    /* View Events */
    const onSearch = (value: string) => {
        if (!accessToken) return;

        searchStr = value;
        setSearch(value);
        setPanel(0);
        if (value === '') {
            setSitesResults(emptyList);
            setLocksResults(emptyList);
            setIsLoadingLocksResults(false);
            setIsLoadingSitesResults(false);
        } else {
            getLocksSearchList(accessToken, value, { page: 0, pageSize: locksResults.pageSize });
            getSitesSearchList(accessToken, value, { page: 0, pageSize: sitesResults.pageSize });
        }
    };

    const onFocus = (value: boolean) => {
        setSitesResults(emptyList);
        setLocksResults(emptyList);
        setIsLoadingLocksResults(false);
        setIsLoadingSitesResults(false);
        setFocus(value);
        setPanel(0);
    };

    const onLoading = (value: boolean) => {
        setIsLoadingLocksResults(value);
        setIsLoadingSitesResults(value);
    };

    const onWatchMore = (value: number) => {
        setPanel(value);
    };

    const loadMoreData = (list: 'site' | 'locksResult' | 'sitesResult') => {
        if (list === 'site') {
            if (isLoadingSites) return;

            if (accessToken) {
                getSitesList(accessToken, { page: sites.page, pageSize: sites.pageSize });
            }
        }
        if (list === 'locksResult') {
            if (isLoadingLocksResults) return;

            if (accessToken) {
                getLocksSearchList(accessToken, search, { page: locksResults.page, pageSize: locksResults.pageSize });
            }
        }

        if (list === 'sitesResult') {
            if (isLoadingSitesResults) return;

            if (accessToken) {
                getSitesSearchList(accessToken, search, { page: sitesResults.page, pageSize: sitesResults.pageSize });
            }
        }
    };

    const onSiteItemPressed = (site: SiteDtoSimple) => {
        navigate(`/mobile/sites/locks`);
        setSelectedSite(site);
    };

    const onLockItemPressed = (lockId: string) => {
        onOpenLockAdministration({ v: 1, data: { lock_id: lockId } });
    };

    /* Private Methods */
    const getLocksSearchList = (accessToken: string, search: string, pagination: PaginationRequest) => {
        setIsLoadingLocksResults(true);
        lockService
            .searchLocksByName(accessToken, search, pagination)
            .then((data) => {
                if (search === searchStr) {
                    const newResults = data.locks.map(mapLockToResult);
                    const newList = {
                        list: pagination.page === 0 ? newResults : [...locksResults.list, ...newResults],
                        page: pagination.page === 0 ? 1 : pagination.page + 1,
                        totalItems: data.total,
                        pageSize: pagination.pageSize,
                    };
                    setLocksResults(newList);
                    setIsLoadingLocksResults(false);
                }
            })
            .catch((error) => {
                console.log('lock-list-error', error);
            });
    };

    const getSitesSearchList = (accessToken: string, search: string, pagination: PaginationRequest) => {
        setIsLoadingSitesResults(true);
        sitesService
            .listSites(accessToken, search, pagination)
            .then((data) => {
                if (search === searchStr) {
                    const newResults = data.sites;
                    const newList = {
                        list: pagination.page === 0 ? newResults : [...sitesResults.list, ...newResults],
                        page: pagination.page === 0 ? 1 : pagination.page + 1,
                        totalItems: data.total,
                        pageSize: pagination.pageSize,
                    };
                    setSitesResults(newList);
                    setIsLoadingSitesResults(false);
                }
            })
            .catch((error) => {
                console.log('site-list-error', error);
            });
    };

    const getSitesList = (accessToken: string, pagination: PaginationRequest) => {
        setIsLoadingSites(true);
        sitesService
            .listSites(accessToken, '', pagination)
            .then((data) => {
                const newList = {
                    list: pagination.page === 0 ? data.sites : [...sites.list, ...data.sites],
                    page: pagination.page === 0 ? 1 : pagination.page + 1,
                    totalItems: data.total,
                    pageSize: pagination.pageSize,
                };
                setSites(newList);
                setIsLoadingSites(false);
            })
            .catch((error) => {
                console.log('site-list-error', error);
            });
    };

    const mapLockToResult = (lock: LockDtoMinimal): LockDtoSimple => {
        return {
            lockName: lock.lock_name,
            siteName: lock.site_name,
            lockId: lock.id,
        };
    };

    // Return state and events
    return {
        focus,
        panel,
        sites,
        locksResults,
        sitesResults,
        isLoadingSites,
        isLoadingLocksResults,
        isLoadingSitesResults,
        onSiteItemPressed,
        onLockItemPressed,
        loadMoreData,
        onSearch,
        onFocus,
        onLoading,
        onWatchMore,
    };
};
