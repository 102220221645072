import { RfidCardCreationIntroductionController } from 'fragments/rfidcards/fragments/rfid-card-creation-introduction/interfaces';
import { onAddCard } from 'mobile/rfid/rfid-functions';
import { useSearchParams } from 'react-router-dom';

export const useRfidCardCreationIntroductionController = (): /* <--Dependency Injections  like services hooks */
RfidCardCreationIntroductionController => {
    const [searchParams] = useSearchParams();

    /* State */
    const deviceId = searchParams.get('device_id')?.toLowerCase() || '';

    /* Listeners */

    /* View Events */
    const onAgreePressed = () => {
        onAddCard({
            v: 2,
            data: {
                device_id: deviceId,
            },
        });
    };

    /* Private Methods */

    // Return state and events
    return { onAgreePressed };
};
