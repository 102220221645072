import axios from 'axios';
// import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useErrorHandler } from 'tools/error-handler';
import {
    CreatePasscodeInput,
    CreatePasscodeResponse,
    GetPasscodeDetail,
    PaginatedPasscodesResponse,
    Passcodes,
    PasscodesDto,
    PasscodesListInput,
} from './passcodes.service';

export const useAPIPasscodes = (): Passcodes => {
    // const mobileURL = 'https://apitest.accefy.io/api/v6';
    // const [session] = useLocalSession();
    const errorHandler = useErrorHandler();
    const listPasscodes = async ({ accessToken, search, pagination, sort }: PasscodesListInput) => {
        sort = sort || { order: 'descend', orderBy: 'since' }; // since || state || label ??
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_PASSCODES_BASE_URL +
                    `/user/locks/${search.lockId}/passcodes?page=${pagination.page}&pageSize=${
                        pagination.pageSize
                    }&order=${sort.order}&orderBy=${sort.orderBy}&label=${search.input !== '' ? search.input : ''}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );

            return {
                total: response.data.total,
                passcodes: response.data.passcodes.map((passcode: PasscodesDto) => ({
                    ...passcode,
                    id: passcode.id.toLowerCase(),
                })),
                search: search.input,
            } as PaginatedPasscodesResponse;
        } catch (err: unknown) {
            errorHandler(err);
            throw err;
        }
    };

    const getPasscodeDetail = async ({ accessToken, passcodeId }: GetPasscodeDetail) => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_MOBILE_PASSCODES_BASE_URL + `/user/passcodes/${passcodeId}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { ...response.data, id: response.data?.id.toLowerCase() };
        } catch (err: unknown) {
            errorHandler(err);
            throw err;
        }
    };

    const createPasscode = async (accessToken: string, input: CreatePasscodeInput): Promise<CreatePasscodeResponse> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_MOBILE_PASSCODES_BASE_URL + `/user/passcodes`,
                input,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return { ...response.data, id: response.data.id.toLowerCase() } as CreatePasscodeResponse;
        } catch (err: unknown) {
            errorHandler(err);
            const error = err as ErrorDto;
            throw error.response.data.msg;
        }
    };

    return {
        listPasscodes,
        getPasscodeDetail,
        createPasscode,
    };
};
