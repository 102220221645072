import Page404 from 'components/page-404/page-404.component';
import Page500 from 'components/page-500/page-500.component';
import PageInvalidSession from 'components/page-invalid-session/page-invalid-session.component';
import PageUpdateApp from 'components/page-update-app/page-update-app.component';
import KeysCreationProvider from 'fragments/keys/fragments/mobile-key-creation/context/keys-creation-provider.component';
import { MobileKeyCreationFragment } from 'fragments/keys/fragments/mobile-key-creation/mobile-key-creation.fragment';
import PasscodesProvider from 'fragments/passcodes/context/passcodes-provider.component';
import { PasscodeCreationFragment } from 'fragments/passcodes/fragments/passcode-creation/passcode-creation.fragment';
import { PasscodeDetailsFragment } from 'fragments/passcodes/fragments/passcode-details/passcode-details.fragment';
import { PasscodeListFragment } from 'fragments/passcodes/fragments/passcode-list/passcode-list.fragment';
import { InfoModalFragment } from 'fragments/common/info-modal/info-modal.fragment';
import { RfidCardCreationIntroductionFragment } from 'fragments/rfidcards/fragments/rfid-card-creation-introduction/rfid-card-creation-introduction.fragment';
import { RfidCardCreationResultWarningFragment } from 'fragments/rfidcards/fragments/rfid-card-creation-result-warning/rfid-card-creation-result-warning.fragment';
import { RfidCardCreationResultInfoFragment } from 'fragments/rfidcards/fragments/rfid-card-creation-result-info/rfid-card-creation-result-info.fragment';
import { RfidCardCreationResultSuccessFragment } from 'fragments/rfidcards/fragments/rfid-card-creation-result-success/rfid-card-creation-result-success.fragment';
import { RfidCardCreationValidityFragment } from 'fragments/rfidcards/fragments/rfid-card-creation-validity/rfid-card-creation-validity.fragment';
import { RfidCardDetailFragment } from 'fragments/rfidcards/fragments/rfid-card-detail/rfid-card-detail.fragment';
import { RfidCardListFragment } from 'fragments/rfidcards/fragments/rfid-card-list/rfid-card-list.fragment';
import { IndexPagesFragment } from 'fragments/tools/index-pages/index-pages.fragment';
import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { RfidCardDeleteErrorLockFragment } from 'fragments/rfidcards/fragments/rfid-card-delete-error-lock/rfid-card-delete-error-lock.fragment';
import { KeysListFragment } from 'fragments/keys/fragments/keys-list/keys-list.fragment';
import { KeysDetailFragment } from 'fragments/keys/fragments/keys-detail/keys-detail.fragment';
import { SiteListFragment } from 'fragments/sites/fragments/site-list/site-list.fragment';
import { LockListFragment } from 'fragments/sites/fragments/lock-list/lock-list.fragment';
import SitesProvider from 'fragments/sites/context/sites-provider.component';
// import LockAdministrationProvider from 'fragments/locks/fragments/context/lock-administration-provider.component'; // TODO: Descomentar en una proxima version
// import { LockAdministrationFragment } from 'fragments/locks/fragments/lock-administration/lock-administration.fragment'; // TODO: Descomentar en una proxima version
import { NotificationListFragment } from 'fragments/notification/fragments/notification-list/notification-list.fragment';

export const AppRoutes: React.FC = () => {
    return (
        <div>
            <Routes>
                <Route path={'/mobile'}>
                    <Route path={'sites'}>
                        <Route
                            index
                            element={
                                <SitesProvider>
                                    <SiteListFragment />
                                </SitesProvider>
                            }
                        />
                        <Route
                            path={'locks'}
                            element={
                                <SitesProvider>
                                    <LockListFragment />
                                </SitesProvider>
                            }
                        />
                    </Route>
                    {/* <Route path={'locks'}>
                        <Route
                            // TODO: Descomentar en una proxima version
                            path={'administration'}
                            element={
                                <LockAdministrationProvider>
                                    <LockAdministrationFragment />
                                </LockAdministrationProvider>
                            }
                        />
                    </Route> */}
                    <Route path={'keys'}>
                        <Route index element={<KeysListFragment />} />
                        <Route
                            path={'creation'}
                            element={
                                <KeysCreationProvider>
                                    <MobileKeyCreationFragment />
                                </KeysCreationProvider>
                            }
                        />
                        <Route path={'detail'} element={<KeysDetailFragment />} />
                    </Route>
                    <Route path={'passcodes'}>
                        <Route
                            index
                            element={
                                <PasscodesProvider>
                                    <PasscodeListFragment />
                                </PasscodesProvider>
                            }
                        />
                        <Route path={'detail'} element={<PasscodeDetailsFragment />} />
                        <Route path={'creation'} element={<PasscodeCreationFragment />} />
                    </Route>
                    <Route path={'rfid-cards'}>
                        <Route index element={<RfidCardListFragment />} />
                        <Route path={'delete'}>
                            <Route path={'error'} element={<RfidCardDeleteErrorLockFragment />} />
                        </Route>
                        <Route path={'detail'} element={<RfidCardDetailFragment />} />
                        <Route path={'creation'}>
                            <Route path={'introduction'} element={<RfidCardCreationIntroductionFragment />} />
                            <Route path={'owner'} element={<Navigate to="/mobile/update-app" replace />} />
                            <Route path={'validity'} element={<RfidCardCreationValidityFragment />} />
                            <Route path={'success'} element={<RfidCardCreationResultSuccessFragment />} />
                            <Route path={'warning'} element={<RfidCardCreationResultWarningFragment />} />
                            <Route path={'info'} element={<RfidCardCreationResultInfoFragment />} />
                        </Route>
                    </Route>
                    <Route path={'notification'} element={<NotificationListFragment />} />
                    <Route path={'modal'} element={<InfoModalFragment />} />
                    <Route path={'update-app'} element={<PageUpdateApp />} />
                    <Route path={'invalid-session'} element={<PageInvalidSession />} />
                    <Route path={'404'} element={<Page404 />} />
                    <Route path={'500'} element={<Page500 />} />
                </Route>
                <Route path={'/index-pages'} element={<IndexPagesFragment />} />
                <Route path="*" element={<Navigate to="/mobile/404" replace />} />
            </Routes>
        </div>
    );
};
