import React from 'react';
import 'fragments/rfidcards/fragments/rfid-card-detail/rfid-card-detail.scss';
import { RfidCardDetailFragmentProps } from 'fragments/rfidcards/fragments/rfid-card-detail/interfaces';
import { useRfidCardDetailController } from 'fragments/rfidcards/fragments/rfid-card-detail/rfid-card-detail.controller';
import { Button, Col, Divider, Modal, PageHeader, Result, Row, Space, Spin, Tag, Typography } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import { ClockCircleOutlined, CalendarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import XCircleIcon from 'assets/icons/x-circle-icon';
const { Text, Title } = Typography;
const { confirm } = Modal;

export const RfidCardDetailFragment: React.FC<RfidCardDetailFragmentProps> = (props) => {
    const { useController = useRfidCardDetailController } = props;
    const { isLoadingDetails, errorDetails, rfidCardDetails, onDeleteButtonPressed, onRetryCardConfigButtonPressed } =
        useController();
    const { translate } = useTranslator();

    const showConfirmDeleteModal = (id: string, cardNumber: string) => {
        confirm({
            title: <Text strong>{translate({ key: 'general.sure-to-delete' })}</Text>,
            icon: <ExclamationCircleOutlined />,
            cancelButtonProps: { className: 'btn-cancel' },
            okButtonProps: { className: 'delete' },
            content: translate({ key: 'general.one-way-action' }),
            okText: translate({ key: 'general.remove' }),
            cancelText: translate({ key: 'general.cancel' }),
            onOk() {
                onDeleteButtonPressed(id, cardNumber);
            },
            onCancel() {
                () => {
                    console.log();
                };
            },
        });
    };
    // Render
    return (
        <div className={'rfid-card-detail'}>
            {!isLoadingDetails && rfidCardDetails && !errorDetails && (
                <>
                    <PageHeader className="rfid-card-details-header" title={rfidCardDetails.label} />
                    <div className="rfid-card-details-container">
                        <Space direction="vertical">
                            <h3 className="rfid-card-details-container-label">
                                {translate({ key: 'label.card' })}: <strong>{rfidCardDetails.cardNumber}</strong>
                            </h3>
                            <h3 className="rfid-card-details-container-label">
                                {translate({ key: 'label.lock' })}: <strong>{rfidCardDetails.deviceName}</strong>
                            </h3>
                            <h3 className="rfid-card-details-container-label">
                                {translate({ key: 'label.state' })}:{' '}
                                {(() => {
                                    switch (rfidCardDetails?.state) {
                                        case 1:
                                            return (
                                                <Tag
                                                    color="processing"
                                                    style={{ fontSize: '.875rem', fontWeight: 'bold' }}
                                                >
                                                    {translate({ key: 'rfid-card.label.pending' })}
                                                </Tag>
                                            );
                                        case 2:
                                            return (
                                                <Tag
                                                    color="success"
                                                    style={{ fontSize: '.875rem', fontWeight: 'bold' }}
                                                >
                                                    {translate({ key: 'rfid-card.label.valid' })}
                                                </Tag>
                                            );
                                        case 3:
                                            return (
                                                <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                                    {translate({ key: 'rfid-card.label.deleted' })}
                                                </Tag>
                                            );

                                        case 4:
                                            return (
                                                <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                                    {translate({ key: 'rfid-card.label.overwritten' })}
                                                </Tag>
                                            );
                                        case 5:
                                            return (
                                                <Tag color="error" style={{ fontSize: '.875rem', fontWeight: 'bold' }}>
                                                    {translate({ key: 'rfid-card.label.expired' })}
                                                </Tag>
                                            );
                                        case 6:
                                            return (
                                                <Tag
                                                    color="processing"
                                                    style={{ fontSize: '.875rem', fontWeight: 'bold' }}
                                                >
                                                    {translate({ key: 'rfid-card.label.deleting' })}
                                                </Tag>
                                            );
                                        default:
                                            return (
                                                <Tag
                                                    color="processing"
                                                    style={{ fontSize: '.875rem', fontWeight: 'bold' }}
                                                >
                                                    {translate({ key: 'rfid-card.label.unknown' })}
                                                </Tag>
                                            );
                                    }
                                })()}
                            </h3>
                            <h3 className="rfid-card-details-container-label">
                                {translate({ key: 'label.responsible' })}:{' '}
                                <strong>{rfidCardDetails?.createdByEmail}</strong>
                            </h3>
                            <h3 className="rfid-card-details-container-label">
                                {translate({ key: 'label.registered-at' })}:{' '}
                                <strong>{rfidCardDetails?.createdAt}</strong>
                            </h3>
                        </Space>

                        <Divider style={{ margin: '1rem 0' }} />

                        {rfidCardDetails.typeId === 2 && (
                            <Row justify="space-between">
                                {rfidCardDetails.keySchedules.map((keyOption) => {
                                    return (
                                        <Col
                                            xs={3}
                                            key={keyOption.key}
                                            style={{
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Tag
                                                className="rfid-card-details-schedule-tag"
                                                style={{
                                                    color: keyOption.selected ? '#777' : '#aaa',
                                                    backgroundColor: keyOption.selected
                                                        ? 'rgba(159, 151, 189, 0.2)'
                                                        : '#eeea',
                                                }}
                                                key={keyOption.key}
                                            >
                                                {keyOption.label}
                                            </Tag>
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                        <div className="rfid-card-details-container-tags">
                            {(() => {
                                switch (rfidCardDetails.typeId) {
                                    case 1:
                                        return (
                                            <Tag icon={<CalendarOutlined />} className="rfid-card-details-tag">
                                                {`${rfidCardDetails.validity.from} > ${rfidCardDetails.validity.to}`}
                                            </Tag>
                                        );
                                    case 2:
                                        return (
                                            <>
                                                <Tag icon={<ClockCircleOutlined />} className="rfid-card-details-tag">
                                                    {`${rfidCardDetails.validity.fromHour} > ${rfidCardDetails.validity.toHour} (${rfidCardDetails.validity.timeZone})`}
                                                </Tag>
                                                <Tag icon={<CalendarOutlined />} className="rfid-card-details-tag">
                                                    {`${rfidCardDetails.validity.from} > ${rfidCardDetails.validity.to}`}
                                                </Tag>
                                            </>
                                        );
                                    case 3:
                                    default:
                                        return (
                                            <Tag icon={<CalendarOutlined />} className="rfid-card-details-tag">
                                                {translate({ key: 'general.permanent' })}
                                            </Tag>
                                        );
                                }
                            })()}
                        </div>
                        <div style={{ paddingTop: '1rem', display: 'flex', gap: '1rem' }}>
                            <Button
                                style={{ display: rfidCardDetails.state > 2 ? 'none' : undefined }}
                                className="rfid-card-details-container-button"
                                onClick={() => showConfirmDeleteModal(rfidCardDetails.id, rfidCardDetails.cardNumber)}
                            >
                                {translate({ key: 'general.delete' })}
                            </Button>
                            {/* <Button
                                style={{ display: rfidCardDetails.state !== 2 ? 'none' : undefined }}
                                className="rfid-card-details-finish-config-button"
                                onClick={() => onRetryCardConfigButtonPressed()}
                            >
                                {translate({ key: 'general.finish-config' })}
                            </Button> */}
                        </div>
                    </div>
                </>
            )}
            {isLoadingDetails && (
                <div className="loading-spiner">
                    <Spin />
                </div>
            )}
            {errorDetails && (
                <div className={'rfid-card-detail-deleted-card'}>
                    <Result
                        style={{ width: '100%' }}
                        icon={<XCircleIcon style={{ color: '#F23D4FCC' }} />}
                        title={
                            <Title className={'rfid-card-detail-deleted-card-title'}>
                                {translate({ key: 'rfid-card.deleted-card' })}
                            </Title>
                        }
                    />
                </div>
            )}
        </div>
    );
};
