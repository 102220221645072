import React from 'react';
import 'fragments/passcodes/fragments/passcode-creation/passcode-creation.scss';
import { PasscodeCreationFragmentProps } from 'fragments/passcodes/fragments/passcode-creation/interfaces';
import { usePasscodeCreationController } from 'fragments/passcodes/fragments/passcode-creation/passcode-creation.controller';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space,
    Spin,
    Tabs,
    TimePicker,
    Typography,
} from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import CheckIcon from 'assets/icons/check-custom-icon';
import CustomHeader from 'components/custom-header/custom-header.component';
import FormDurationPicker from 'components/form-duration-picker/form-duration-picker.component';
const { Option } = Select;
const { Text, Title } = Typography;
const { TabPane } = Tabs;

export const PasscodeCreationFragment: React.FC<PasscodeCreationFragmentProps> = (props) => {
    const { useController = usePasscodeCreationController } = props;
    const controller = useController();
    const { translate } = useTranslator();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textTransform: 'capitalize',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        textTransform: 'capitalize',
                        alignItems: 'left',
                    }}
                >
                    {
                        <div>
                            <b>{lock.site_name}</b>
                            {' - '}
                            {lock.lock_name}
                        </div>
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        textTransform: 'capitalize',
                        alignItems: 'right',
                    }}
                ></div>
            </div>
        </Option>
    ));

    // Render
    return (
        <div className={'passcode-creation'}>
            {!controller.isLockListingLoading ? (
                <>
                    <CustomHeader>{translate({ key: 'passcodes.create-modal' })}</CustomHeader>
                    {controller.currentStep == 0 && (
                        <>
                            <Form
                                id="create-passcode-form"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                layout="horizontal"
                                size="middle"
                                className={'passcode-creation-form'}
                                form={controller.form}
                                onFinish={controller.onCreateButtonPressed}
                            >
                                <Tabs
                                    defaultActiveKey={controller.selectedType.toString()}
                                    onChange={controller.onTypeChange}
                                    centered
                                >
                                    <TabPane key={1} tab={translate({ key: 'passcodes.single-use' })}>
                                        {controller.selectedType === 1 && (
                                            <>
                                                <Text type="secondary" strong style={{ marginBottom: '1rem' }}>
                                                    {translate({ key: 'passcodes.passcode-single-use-description-1' })}{' '}
                                                    <span style={{ color: '#262626d9' }}>
                                                        {translate({
                                                            key: 'passcodes.passcode-single-use-description-2',
                                                        })}
                                                    </span>{' '}
                                                    {translate({ key: 'passcodes.passcode-single-use-description-3' })}
                                                </Text>

                                                <Divider />

                                                <Col id="create-passcode-lock-select">
                                                    <Form.Item
                                                        label={
                                                            <Title
                                                                level={5}
                                                                type="secondary"
                                                                style={{ marginBottom: '0px' }}
                                                            >
                                                                {translate({ key: 'label.lock' })}
                                                            </Title>
                                                        }
                                                        required
                                                        // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                                        rules={[
                                                            {
                                                                required:
                                                                    controller.selectedLock === undefined
                                                                        ? true
                                                                        : false,
                                                                message:
                                                                    controller.selectedLock === undefined
                                                                        ? translate({ key: 'form.rules-required' })
                                                                        : '',
                                                            },
                                                        ]}
                                                        name="lockName"
                                                    >
                                                        {!controller.isLockListingLoading && (
                                                            <Select
                                                                showSearch
                                                                placeholder={translate({
                                                                    key: 'form.placeholder.lock.name',
                                                                })}
                                                                style={{ width: '100%' }}
                                                                className={'passcode-creation-form-select'}
                                                                value={controller.selectedLock?.id}
                                                                defaultValue={controller.selectedLock?.id}
                                                                onChange={controller.onLockSelect}
                                                                filterOption={(inputValue, option) => {
                                                                    const opt = controller.locks.find(
                                                                        (item) => item.id == option?.value,
                                                                    );
                                                                    return !!opt?.lock_name
                                                                        .toLowerCase()
                                                                        .includes(inputValue.toLowerCase());
                                                                }}
                                                                loading={controller.isLockListingLoading}
                                                            >
                                                                {optionLocks}
                                                            </Select>
                                                        )}
                                                        {controller.isLockListingLoading && (
                                                            <Select
                                                                placeholder={translate({
                                                                    key: 'form.placeholder.lock.loading',
                                                                })}
                                                                style={{ width: '100%' }}
                                                                className={'passcode-creation-form-select'}
                                                                loading={controller.isLockListingLoading}
                                                            ></Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                <Divider />

                                                <Col xs={24} id="create-passcode-picker">
                                                    <Form.Item
                                                        label={
                                                            <Text type="secondary" strong>
                                                                {translate({ key: 'label.duration' })}
                                                            </Text>
                                                        }
                                                        required
                                                    >
                                                        <Col span={24}>
                                                            <Form.Item
                                                                required
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: translate({
                                                                            key: 'form.rules-required',
                                                                        }),
                                                                    },
                                                                ]}
                                                                name="date_picker"
                                                                style={{ marginBottom: '10px' }}
                                                            >
                                                                <DatePicker
                                                                    style={{ width: '100%' }}
                                                                    onChange={(e) => {
                                                                        return controller.onPickedDate(e, 'since');
                                                                    }}
                                                                    getPopupContainer={() =>
                                                                        document.getElementById(
                                                                            'create-passcode-picker',
                                                                        ) as HTMLElement
                                                                    }
                                                                    disabledDate={controller.disabledDate}
                                                                    className={'passcode-creation-picker-item'}
                                                                    format="DD/MM/YYYY"
                                                                    disabled={controller.isLoading}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={24}>
                                                            <Form.Item
                                                                style={{ marginBottom: '10px' }}
                                                                required
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: translate({
                                                                            key: 'form.rules-required',
                                                                        }),
                                                                    },
                                                                ]}
                                                                name="time_picker"
                                                            >
                                                                <TimePicker
                                                                    style={{ width: '100%' }}
                                                                    onChange={controller.onPickedHour}
                                                                    getPopupContainer={() =>
                                                                        document.getElementById(
                                                                            'create-passcode-picker',
                                                                        ) as HTMLElement
                                                                    }
                                                                    className={'passcode-creation-picker-item'}
                                                                    format="HH:00"
                                                                    disabledTime={(e) =>
                                                                        controller.disabledTime(e, false)
                                                                    }
                                                                    disabled={
                                                                        controller.isLoading ||
                                                                        !controller.selectedDateRange.sinceDate.length
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        {controller.formatedDates.length === 2 && (
                                                            <Text type="secondary" strong>
                                                                {translate({ key: 'passcodes.the-code-expire-1' })}
                                                                <span style={{ color: 'red' }}>
                                                                    {translate({ key: 'passcodes.the-code-expire-2' })}
                                                                </span>
                                                                :
                                                                <span style={{ color: '#262626d9' }}>
                                                                    {' '}
                                                                    {controller.formatedDates[1]}
                                                                </span>
                                                            </Text>
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                <Divider />

                                                <Form.Item
                                                    label={
                                                        <Text type="secondary" strong>
                                                            {translate({ key: 'label.label' })}
                                                        </Text>
                                                    }
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate({ key: 'form.rules-required' }),
                                                        },
                                                    ]}
                                                    name="code_label"
                                                >
                                                    <Input
                                                        placeholder={translate({
                                                            key: 'form.placeholder.passcodes.label',
                                                        })}
                                                        maxLength={20}
                                                        minLength={3}
                                                        size={'large'}
                                                        style={{
                                                            width: '100%',
                                                            borderRadius: '.5rem',
                                                            lineHeight: '32px',
                                                        }}
                                                        onChange={controller.onLabelInputChange}
                                                        disabled={controller.isLoading}
                                                    />
                                                </Form.Item>
                                                <Row justify={'end'}>
                                                    <Col span={11}>
                                                        <Button
                                                            form="create-passcode-form"
                                                            key="submit"
                                                            style={{ width: '100%' }}
                                                            type="primary"
                                                            className={'passcode-creation-form-btn'}
                                                            loading={controller.isLoading}
                                                            htmlType="submit"
                                                        >
                                                            {translate({ key: 'passcodes.generate' })}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </TabPane>
                                    {/* <TabPane key={2} tab={translate({ key: 'passcodes.custom' })}>
                                        {controller.selectedType === 2 && (
                                            <>
                                                <Text type="secondary" strong style={{ marginBottom: '1rem' }}>
                                                    {translate({ key: 'passcodes.passcode-temporary-description-1' })}{' '}
                                                    <span style={{ color: '#262626d9' }}>
                                                        {translate({
                                                            key: 'passcodes.passcode-temporary-description-2',
                                                        })}
                                                    </span>{' '}
                                                    {translate({ key: 'passcodes.passcode-temporary-description-3' })}
                                                </Text>

                                                <Divider />

                                                <Col id="create-passcode-lock-select">
                                                    <Form.Item
                                                        label={
                                                            <Title
                                                                level={5}
                                                                type="secondary"
                                                                style={{ marginBottom: '0px' }}
                                                            >
                                                                {translate({ key: 'label.lock' })}
                                                            </Title>
                                                        }
                                                        required
                                                        // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                                        rules={[
                                                            {
                                                                required:
                                                                    controller.selectedLock === undefined
                                                                        ? true
                                                                        : false,
                                                                message:
                                                                    controller.selectedLock === undefined
                                                                        ? translate({ key: 'form.rules-required' })
                                                                        : '',
                                                            },
                                                        ]}
                                                        name="lockName"
                                                    >
                                                        {!controller.isLockListingLoading && (
                                                            <Select
                                                                showSearch
                                                                placeholder={translate({
                                                                    key: 'form.placeholder.lock.name',
                                                                })}
                                                                style={{ width: '100%' }}
                                                                className={'passcode-creation-form-select'}
                                                                value={controller.selectedLock?.id}
                                                                defaultValue={controller.selectedLock?.id}
                                                                onChange={controller.onLockSelect}
                                                                filterOption={(inputValue, option) => {
                                                                    const opt = controller.locks.find(
                                                                        (item) => item.id == option?.value,
                                                                    );
                                                                    return !!opt?.lock_name
                                                                        .toLowerCase()
                                                                        .includes(inputValue.toLowerCase());
                                                                }}
                                                                loading={controller.isLockListingLoading}
                                                            >
                                                                {optionLocks}
                                                            </Select>
                                                        )}
                                                        {controller.isLockListingLoading && (
                                                            <Select
                                                                placeholder={translate({
                                                                    key: 'form.placeholder.lock.loading',
                                                                })}
                                                                style={{ width: '100%' }}
                                                                className={'passcode-creation-form-select'}
                                                                loading={controller.isLockListingLoading}
                                                            ></Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                <Divider />

                                                <FormDurationPicker
                                                    dateRange={controller.customDateRange}
                                                    setDateRange={controller.setCustomDateRange}
                                                    hour
                                                    durations={[
                                                        {
                                                            amount: 1,
                                                            unit: 'day',
                                                            label: '1 ' + translate({ key: 'general.day' }),
                                                        },
                                                        {
                                                            amount: 1,
                                                            unit: 'month',
                                                            label: '1 ' + translate({ key: 'general.month' }),
                                                        },
                                                        {
                                                            amount: 3,
                                                            unit: 'month',
                                                            label: '3 ' + translate({ key: 'general.months' }),
                                                        },
                                                        {
                                                            amount: 6,
                                                            unit: 'month',
                                                            label: '6 ' + translate({ key: 'general.months' }),
                                                        },
                                                        {
                                                            amount: 1,
                                                            unit: 'year',
                                                            label: '1 ' + translate({ key: 'general.year' }),
                                                        },
                                                    ]}
                                                />

                                                <Divider />

                                                <Form.Item
                                                    label={
                                                        <Text type="secondary" strong>
                                                            {translate({ key: 'label.code' })}
                                                        </Text>
                                                    }
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate({ key: 'form.rules-required' }),
                                                        },
                                                        () => ({
                                                            validator() {
                                                                if (!Number(controller.code)) {
                                                                    return Promise.reject(
                                                                        new Error(`No es un código numérico`),
                                                                    );
                                                                }
                                                                if (controller.code.length > 9) {
                                                                    return Promise.reject(
                                                                        new Error(`No puede tener mas de 9 dígitos`),
                                                                    );
                                                                }
                                                                if (controller.code.length < 4) {
                                                                    return Promise.reject(
                                                                        new Error(`No puede tener menos de 4 dígitos`),
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                    name="code_passcode"
                                                >
                                                    <Input
                                                        placeholder={translate({
                                                            key: 'form.placeholder.passcodes.passcode',
                                                        })}
                                                        type={'number'}
                                                        maxLength={9}
                                                        minLength={4}
                                                        size={'large'}
                                                        style={{
                                                            width: '100%',
                                                            borderRadius: '.5rem',
                                                            lineHeight: '32px',
                                                        }}
                                                        onChange={controller.onPasscodeInputChange}
                                                        disabled={controller.isLoading}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label={
                                                        <Text type="secondary" strong>
                                                            {translate({ key: 'label.label' })}
                                                        </Text>
                                                    }
                                                    required
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: translate({ key: 'form.rules-required' }),
                                                        },
                                                    ]}
                                                    name="code_label"
                                                >
                                                    <Input
                                                        placeholder={translate({
                                                            key: 'form.placeholder.passcodes.label',
                                                        })}
                                                        maxLength={20}
                                                        minLength={3}
                                                        size={'large'}
                                                        style={{
                                                            width: '100%',
                                                            borderRadius: '.5rem',
                                                            lineHeight: '32px',
                                                        }}
                                                        onChange={controller.onLabelInputChange}
                                                        disabled={controller.isLoading}
                                                    />
                                                </Form.Item>
                                                <Row justify={'end'}>
                                                    <Col span={11}>
                                                        <Button
                                                            form="create-passcode-form"
                                                            key="submit"
                                                            style={{ width: '100%' }}
                                                            type="primary"
                                                            className={'passcode-creation-form-btn'}
                                                            loading={controller.isLoading}
                                                            htmlType="submit"
                                                        >
                                                            {translate({ key: 'passcodes.generate' })}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </TabPane> */}
                                </Tabs>
                            </Form>
                        </>
                    )}
                    {controller.currentStep == 1 && (
                        <div className={'passcode-creation-form'}>
                            <Space direction="vertical" align="center" style={{ width: '100%', margin: '1rem 0' }}>
                                <CheckIcon />
                                <Title type="secondary" level={2} style={{ padding: 0, color: '#15B9B0' }}>
                                    {translate({ key: 'passcodes.successfuly-created' })}
                                </Title>
                                <Title level={1}>{controller.createdPasscode.code}</Title>

                                <Text type="secondary" strong>
                                    {translate({ key: 'label.type' })}:
                                    <span style={{ color: '#262626d9' }}>
                                        {' '}
                                        {controller.createdPasscode.type !== 3
                                            ? translate({ key: 'passcodes.single-use' })
                                            : translate({ key: 'passcodes.custom' })}
                                    </span>
                                </Text>
                                <Text type="secondary" strong>
                                    {translate({ key: 'passcodes.valid-since' })}:
                                    <span style={{ color: '#262626d9' }}> {controller.createdPasscode.since}</span>
                                </Text>
                                <Text type="secondary" strong>
                                    {translate({ key: 'passcodes.valid-until' })}:
                                    <span style={{ color: '#262626d9' }}> {controller.createdPasscode.until}</span>
                                </Text>

                                <Text type="secondary" strong>
                                    {translate({ key: 'label.lock' })}:
                                    <span style={{ color: '#262626d9', marginRight: '2rem' }}>
                                        {' '}
                                        {controller.createdPasscode.lock_name}
                                    </span>
                                </Text>
                                <Text type="secondary" strong>
                                    {translate({ key: 'label.label' })}:
                                    <span style={{ color: '#262626d9' }}> {controller.createdPasscode.label}</span>
                                </Text>

                                <Text type="secondary" strong>
                                    {translate({ key: 'label.type-to-open' })}
                                    <span style={{ color: '#262626d9' }}> {controller.createdPasscode.code}#</span>
                                </Text>
                            </Space>
                            <Row justify={'space-between'} style={{ width: '100%' }}>
                                <Col span={11}>
                                    <Button
                                        key="create_other"
                                        style={{ width: '100%' }}
                                        className={'passcode-creation-form-btn'}
                                        onClick={controller.onCreateOtherPasscodeButtonPressed}
                                    >
                                        {translate({ key: 'passcodes.generate-other' })}
                                    </Button>
                                </Col>
                                <Col span={11}>
                                    <Button
                                        key="back"
                                        type="primary"
                                        style={{ width: '100%' }}
                                        className={'passcode-creation-form-btn'}
                                        onClick={() =>
                                            controller.onShareButtonPressed(
                                                controller.createdPasscode.code,
                                                controller.createdPasscode.lock_name,
                                            )
                                        }
                                    >
                                        {translate({ key: 'general.share' })}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </>
            ) : (
                <div className={'loading-spiner'}>
                    <Spin size="large" />
                </div>
            )}
        </div>
    );
};
