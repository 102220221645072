import React, { ReactNode, useState } from 'react';
import 'fragments/keys/fragments/mobile-key-creation/mobile-key-creation.scss';
import { MobileKeyCreationFragmentProps } from 'fragments/keys/fragments/mobile-key-creation/interfaces';
import { useMobileKeyCreationController } from 'fragments/keys/fragments/mobile-key-creation/mobile-key-creation.controller';
import { Button, Modal, Input, Row, Col, Typography, Form, Select, Divider, Radio, Calendar, Slider, Spin } from 'antd';
import { useTranslator } from 'tools/view-hooks/translator-hook';
import dayjs from 'dayjs';
import CustomHeader from 'components/custom-header/custom-header.component';
import { useKeysCreationContext } from './context/keys-creation.context';
import FormDurationPicker from 'components/form-duration-picker/form-duration-picker.component';
import FormDaysSelector from 'components/form-days-selector/form-days-selector.component';
import FormTimeSlider from 'components/form-time-slider/form-time-slider.component';
import { CreateKeySummaryFragment } from './fragments/create-key-summary/create-key-summary.fragment';
import { LockDto } from 'services/locks/locks.service';
const { Title, Text } = Typography;
const { Option } = Select;

export const MobileKeyCreationFragment: React.FC<MobileKeyCreationFragmentProps> = (props) => {
    const { useController = useMobileKeyCreationController } = props;
    const controller = useController();
    const { translate } = useTranslator();
    const { isCreateKeySummaryModalVisible } = useKeysCreationContext();

    const optionLocks = controller.locks.map((lock) => (
        <Option key={lock.id} value={lock.id}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    textTransform: 'capitalize',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        textTransform: 'capitalize',
                        alignItems: 'left',
                    }}
                >
                    {
                        <div>
                            <b>{lock.site_name}</b>
                            {' - '}
                            {lock.lock_name}
                        </div>
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        textTransform: 'capitalize',
                        alignItems: 'right',
                    }}
                >
                    {/* {lock.lock_status_id == 0 && <DownCircleOutlined style={{ color: '#cf1411' }} />}
                    {lock.lock_type_id == '5' && <WarningOutlined style={{ color: '#db7c00' }} />} */}
                </div>
            </div>
        </Option>
    ));

    // Render
    return (
        <>
            {!isCreateKeySummaryModalVisible && (
                <>
                    <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
                    <CustomHeader>{translate({ key: 'key.create-title' })}</CustomHeader>
                    <Form
                        id="create-key-form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        layout="horizontal"
                        size="middle"
                        className={'create-key-form'}
                        onFinish={controller.onCreateKeyFormSubmit}
                        form={controller.form}
                        initialValues={{
                            'time-picker': [dayjs().startOf('day'), dayjs().endOf('day')],
                        }}
                        // title={}
                    >
                        {!controller.isReady && (
                            <div className={'loading-spiner'}>
                                <Spin></Spin>
                            </div>
                        )}
                        {controller.isReady && (
                            <div>
                                <Col id="create-key-lock-select">
                                    <Form.Item
                                        label={
                                            <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                                                {translate({ key: 'label.lock' })}
                                            </Title>
                                        }
                                        required
                                        // rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                        rules={[
                                            {
                                                required: controller.selectedLock === undefined ? true : false,
                                                message:
                                                    controller.selectedLock === undefined
                                                        ? translate({ key: 'form.rules-required' })
                                                        : '',
                                            },
                                        ]}
                                        name="lockName"
                                    >
                                        {!controller.isLockListingLoading && (
                                            <Select
                                                showSearch
                                                placeholder={translate({ key: 'form.placeholder.lock.name' })}
                                                style={{ width: '100%' }}
                                                className={'lock-select'}
                                                value={controller.selectedLock?.id}
                                                defaultValue={controller.selectedLock?.id}
                                                onChange={controller.onLockSelect}
                                                filterOption={(inputValue, option) => {
                                                    const opt: LockDto | undefined = controller.locks.find(
                                                        (item) => item.id == option?.value,
                                                    );
                                                    return !!opt?.lock_name
                                                        .toLowerCase()
                                                        .includes(inputValue.toLowerCase());
                                                }}
                                                loading={controller.isLockListingLoading}
                                            >
                                                {optionLocks}
                                            </Select>
                                        )}
                                        {controller.isLockListingLoading && (
                                            <Select
                                                placeholder={translate({ key: 'form.placeholder.lock.name' })}
                                                style={{ width: '100%' }}
                                                className={'lock-select'}
                                                loading={controller.isLockListingLoading}
                                            ></Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Form.Item
                                    label={
                                        <Title level={5} type="secondary" style={{ marginBottom: '0px' }}>
                                            {translate({ key: 'label.user' })}
                                        </Title>
                                    }
                                    required
                                    rules={[{ required: true, message: translate({ key: 'form.rules-required' }) }]}
                                    name="assignee_email"
                                >
                                    <Input
                                        type={'email'}
                                        style={{ width: '100%', borderRadius: '.5rem', lineHeight: '32px' }}
                                        value={controller.assigneeEmail}
                                        onChange={controller.onClientEmailAdd}
                                        placeholder={translate({ key: 'form.placeholder.key.email' })}
                                    />
                                </Form.Item>
                                <Divider style={{ marginBottom: '5px' }} />
                                <FormDurationPicker
                                    dateRange={controller.dateRange}
                                    setDateRange={controller.setDateRange}
                                />
                                {controller.selectedLock != null && (
                                    <>
                                        <FormDaysSelector
                                            dowBytewise={controller.dowBytewise}
                                            setDowBytewise={controller.setDowBytewise}
                                        />
                                        <FormTimeSlider
                                            timeRange={controller.timeRange}
                                            setTimeRange={controller.setTimeRange}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        <Form.Item>
                            {controller.isReady && (
                                <Button
                                    className={'create-key-form-btn'}
                                    htmlType="submit"
                                    type="primary"
                                    style={{ float: 'right' }}
                                    loading={controller.isLoading}
                                >
                                    {translate({ key: 'form.give-key' })}
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </>
            )}
            {isCreateKeySummaryModalVisible && <CreateKeySummaryFragment />}
        </>
    );
};
