import { LockDto, LockDtoMinimal, Locks, PaginatedLocksResponse } from 'services/locks/locks.service';
import axios from 'axios';
import { useLocalSession } from 'auth/helpers/session.hooks';
import { ErrorDto } from 'services/dtos/errors-dto';
import { useErrorHandler } from 'tools/error-handler';
import { PaginationRequest } from 'global/interfaces';

export const useAPILocks = (): Locks => {
    const [session] = useLocalSession();
    const errorHandler = useErrorHandler();

    const listLocksInKeyCreation = async (accessToken: string): Promise<LockDto[]> => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_WEB_BASE_URL + `/user/locks/v2`,
                {
                    device_id: 'device_id',
                },
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            const locks = response.data as LockDto[];
            locks.sort((a: LockDto, b: LockDto): number => {
                if (a.site_name == b.site_name) {
                    return a.lock_name.localeCompare(b.lock_name);
                }
                return a.site_name.localeCompare(b.site_name);
            });
            return locks.map((lock) => ({ ...lock, id: lock.id.toLowerCase() }));
        } catch (error) {
            errorHandler(error);
            throw error;
        }
    };

    const searchLocksByName = async (
        accessToken: string,
        search: string,
        pagination: PaginationRequest,
    ): Promise<PaginatedLocksResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL +
                    `/user/locks?input=${search}&page=${pagination.page}&pageSize=${pagination.pageSize}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );
            return {
                ...response.data,
                locks: response.data.locks.map((lock: LockDtoMinimal) => ({ ...lock, id: lock.id.toLowerCase() })),
            };
        } catch (err: any) {
            errorHandler(err);
            throw err;
        }
    };

    const listLocksBySite = async (
        accessToken: string,
        siteId: string,
        pagination: PaginationRequest,
    ): Promise<PaginatedLocksResponse> => {
        try {
            const response = await axios.get(
                process.env.REACT_APP_API_WEB_BASE_URL +
                    `/user/sites/${siteId}/locks?page=${pagination.page}&pageSize=${pagination.pageSize}`,
                {
                    headers: { Authorization: 'Bearer ' + accessToken },
                },
            );

            return {
                ...response.data,
                locks: response.data.locks.map((lock: LockDtoMinimal) => ({ ...lock, id: lock.id.toLowerCase() })),
            };
        } catch (err: any) {
            errorHandler(err);
            throw err as ErrorDto;
        }
    };

    const getLockById = async (accessToken: string, lockId: string): Promise<LockDto> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_WEB_BASE_URL + `/user/lock/${lockId}`, {
                headers: { Authorization: 'Bearer ' + accessToken },
            });

            return {
                ...response.data,
                id: response.data.id.toLowerCase(),
            };
        } catch (err: any) {
            errorHandler(err);
            throw err as ErrorDto;
        }
    };

    return {
        listLocksInKeyCreation,
        searchLocksByName,
        listLocksBySite,
        getLockById,
    };
};
