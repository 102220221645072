import React from 'react';
import 'fragments/sites/fragments/site-list/site-list.scss';
import { SiteListFragmentProps } from 'fragments/sites/fragments/site-list/interfaces';
import { useSiteListController } from 'fragments/sites/fragments/site-list/site-list.controller';
import SearchHeader from 'components/search-header/search-header.component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Card, Collapse, Divider, Empty, List, Row, Skeleton, Spin, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useTranslator } from 'tools/view-hooks/translator-hook';

const { Text } = Typography;
const { Panel } = Collapse;

export const SiteListFragment: React.FC<SiteListFragmentProps> = (props) => {
    const { translate } = useTranslator();
    const { useController = useSiteListController } = props;
    const controller = useController();

    // Render
    return (
        <div className={'site-list'}>
            <SearchHeader
                onFocus={controller.onFocus}
                onLoading={controller.onLoading}
                onSearch={controller.onSearch}
            />
            {controller.focus && !controller.sitesResults.list.length && !controller.locksResults.list.length ? (
                <>
                    {controller.isLoadingLocksResults || controller.isLoadingSitesResults ? (
                        <Spin className={'site-list-scrollcontainer-spin-fullscreen'} />
                    ) : (
                        <Empty className={'site-list-scrollcontainer-empty'} />
                    )}
                </>
            ) : null}
            {controller.focus && controller.panel === 0 && (
                <>
                    {controller.sitesResults.list.length ? (
                        <>
                            <div className={'site-list-title-watchmore'}>
                                <Text strong className={'site-list-title-watchmore-text'}>
                                    {translate({ key: 'nav.sites' })}
                                </Text>
                                <div className={'site-list-title-watchmore-line'}></div>
                                {/* controller.sitesResults.list.length > 3 && (
                                    <Button
                                        type={'link'}
                                        className={'site-list-title-watchmore-button'}
                                        onClick={() => {
                                            controller.onWatchMore(1);
                                        }}
                                    >
                                        {translate({ key: 'site.see-more' })}
                                    </Button>
                                ) */}
                            </div>
                            <div className={'site-list-small-listitem'}>
                                <List
                                    loading={{
                                        spinning: controller.isLoadingSitesResults,
                                    }}
                                    itemLayout="horizontal"
                                    dataSource={controller.sitesResults.list.slice(0, 3)}
                                    renderItem={(item) => (
                                        <List.Item className={'site-list-card-listitem'}>
                                            <Card
                                                className={'site-list-card'}
                                                onClick={() => {
                                                    controller.onSiteItemPressed(item);
                                                }}
                                            >
                                                <Row justify="space-between" align="middle">
                                                    <Text className={'site-list-card-text'}>{item.name}</Text>
                                                    <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                                {controller.sitesResults.list.length > 3 && (
                                    <Button
                                        type={'primary'}
                                        className={'site-list-title-watchmore-button'}
                                        onClick={() => {
                                            controller.onWatchMore(1);
                                        }}
                                    >
                                        {translate({ key: 'site.see-more' })}
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : null}
                    {controller.locksResults.list.length ? (
                        <>
                            <div className={'site-list-title-watchmore'}>
                                <Text strong className={'site-list-title-watchmore-text'}>
                                    {translate({ key: 'nav.locks' })}
                                </Text>
                                <div className={'site-list-title-watchmore-line'}></div>
                                {/* controller.locksResults.list.length > 3 && (
                                    <Button
                                        type={'link'}
                                        className={'site-list-title-watchmore-button'}
                                        onClick={() => {
                                            controller.onWatchMore(2);
                                        }}
                                    >
                                        {translate({ key: 'site.see-more' })}
                                    </Button>
                                ) */}
                            </div>
                            <div className={'site-list-small-listitem'}>
                                <List
                                    loading={{
                                        spinning: controller.isLoadingLocksResults,
                                    }}
                                    itemLayout="horizontal"
                                    dataSource={controller.locksResults.list.slice(0, 3)}
                                    renderItem={(item) => (
                                        <List.Item className={'site-list-card-listitem'}>
                                            <Card
                                                className={'site-list-card'}
                                                onClick={() => {
                                                    controller.onLockItemPressed(item.lockId);
                                                }}
                                            >
                                                <Row justify="space-between" align="middle">
                                                    <Text className={'site-list-card-text'}>
                                                        <strong>{item.siteName}</strong> - {item.lockName}
                                                    </Text>
                                                    <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                                {controller.locksResults.list.length > 3 && (
                                    <Button
                                        type={'primary'}
                                        className={'site-list-title-watchmore-button'}
                                        onClick={() => {
                                            controller.onWatchMore(2);
                                        }}
                                    >
                                        {translate({ key: 'site.see-more' })}
                                    </Button>
                                )}
                            </div>
                        </>
                    ) : null}
                </>
            )}
            {controller.focus && controller.panel !== 0 && (
                <Collapse accordion ghost expandIconPosition="end" defaultActiveKey={controller.panel}>
                    <Panel
                        header={
                            <Divider plain orientation={'left'} className={'site-list-collapse-divider'}>
                                <strong>{translate({ key: 'nav.sites' })}</strong>
                            </Divider>
                        }
                        key="1"
                        className={'site-list-collapse-panel'}
                    >
                        <div id="scrollableResult-sites" className={'site-list-scrollcontainer-collapse'}>
                            <InfiniteScroll
                                dataLength={controller.sitesResults.list.length}
                                next={() => {
                                    controller.loadMoreData('sitesResult');
                                }}
                                hasMore={controller.sitesResults.list.length < controller.sitesResults.totalItems}
                                loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                scrollableTarget="scrollableResult-sites"
                            >
                                {controller.isLoadingSitesResults ? (
                                    <Spin className={'site-list-scrollcontainer-spin'} />
                                ) : null}
                                <List
                                    loading={{
                                        spinning: false,
                                        style: { display: 'none' },
                                    }}
                                    itemLayout="horizontal"
                                    dataSource={controller.sitesResults.list}
                                    renderItem={(item) => (
                                        <List.Item className={'site-list-card-listitem'}>
                                            <Card
                                                className={'site-list-card'}
                                                onClick={() => {
                                                    controller.onSiteItemPressed(item);
                                                }}
                                            >
                                                <Row justify="space-between" align="middle">
                                                    <Text className={'site-list-card-text'}>{item.name}</Text>
                                                    <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Panel>
                    <Panel
                        header={
                            <Divider plain orientation={'left'} className={'site-list-collapse-divider'}>
                                <strong>{translate({ key: 'nav.locks' })}</strong>
                            </Divider>
                        }
                        key="2"
                        className={'site-list-collapse-panel'}
                    >
                        <div id="scrollableResult-locks" className={'site-list-scrollcontainer-collapse'}>
                            <InfiniteScroll
                                dataLength={controller.locksResults.list.length}
                                next={() => {
                                    controller.loadMoreData('locksResult');
                                }}
                                hasMore={controller.locksResults.list.length < controller.locksResults.totalItems}
                                loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                scrollableTarget="scrollableResult-locks"
                            >
                                {controller.isLoadingLocksResults ? (
                                    <Spin className={'site-list-scrollcontainer-spin'} />
                                ) : null}
                                <List
                                    loading={{
                                        spinning: false,
                                    }}
                                    itemLayout="horizontal"
                                    dataSource={controller.locksResults.list}
                                    renderItem={(item) => (
                                        <List.Item className={'site-list-card-listitem'}>
                                            <Card
                                                className={'site-list-card'}
                                                onClick={() => {
                                                    controller.onLockItemPressed(item.lockId);
                                                }}
                                            >
                                                <Row justify="space-between" align="middle">
                                                    <Text className={'site-list-card-text'}>
                                                        <strong>{item.siteName}</strong> - {item.lockName}
                                                    </Text>
                                                    <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Panel>
                </Collapse>
            )}
            {!controller.focus && (
                <div id="scrollableSites" className={'site-list-scrollcontainer'}>
                    <InfiniteScroll
                        dataLength={controller.sites.list.length}
                        next={() => {
                            controller.loadMoreData('site');
                        }}
                        hasMore={controller.sites.list.length < controller.sites.totalItems}
                        loader={<Skeleton paragraph={{ rows: 1 }} active />}
                        scrollableTarget="scrollableSites"
                    >
                        <List
                            loading={{
                                spinning: controller.isLoadingSites,
                                style: { position: 'fixed', top: '80px' },
                            }}
                            itemLayout="horizontal"
                            dataSource={controller.sites.list}
                            renderItem={(item) => (
                                <List.Item className={'site-list-card-listitem'}>
                                    <Card
                                        className={'site-list-card-sites'}
                                        onClick={() => {
                                            controller.onSiteItemPressed(item);
                                        }}
                                    >
                                        <Row justify="space-between" align="middle">
                                            <Text className={'site-list-card-text'}>{item.name}</Text>
                                            <CaretRightOutlined style={{ color: 'rgba(0, 0, 0, 0.25098)' }} />
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
};
