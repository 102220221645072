import { useEffect, useState } from 'react';
import { IndexPagesController, RoutesRedirections } from 'fragments/tools/index-pages/interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useIndexPagesController = (): /* <--Dependency Injections  like services hooks */ IndexPagesController => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    /* State */
    const [routes, setRoutes] = useState<RoutesRedirections[]>([]);

    const accessToken =
        searchParams.get('access_token') ||
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJhY2NlZnkuaW8iLCJleHAiOjE2OTc1NzAwMjMsInN1YiI6IjRlMzk2MDJhLWViOTYtNDhkOS1iODg0LTBhNzBhZWFhMGIxYSIsImVtYWlsIjoibHJ1aGxAYWNjZWZ5LmlvIiwiYXBwX21ldGFkYXRhIjp7InByb3ZpZGVyIjoiZW1haWwifSwidXNlcl9tZXRhZGF0YSI6e30sImRldmljZV9pZCI6ImFhYTEyMyJ9.yHdcAHwSFoHpPrYlWAuMvvscdZ9CI7NyjX1rBe5cAys';
    const successData = {
        lock_name: 'Puerta Principal',
        label: 'Fulanito',
        nbf_date: '2022-11-29T00:00:00Z',
        nbf_time: '2000-01-01T08:00:00Z',
        expires_at_date: '2022-12-29T00:00:00Z',
        expires_at_time: '2000-01-01T18:00:00Z',
        dow: 31,
        cyclic_rfid_config_enabled: 'true',
    };
    const validityData = {
        label: 'leonardo',
        cardNumber: '1234561231',
        cardId: '56d26622-4c54-4d4e-92a0-599c717b5825',
        deviceId: '0e7d1f76-5072-433e-b9a3-c48753d02505',
        cyclicRfidConfigEnabled: false,
        overwritten: true,
    };
    const rfidCardId = '00e328cc-26af-4956-a339-36a6e829cf2d';
    // const rfidCardId = '4b4d8e00-4934-4a18-9864-b8758f892f1f';
    const passcodeId = '56d26622-4c54-4d4e-92a0-599c717b5825'; //'25a1604e-89a5-474d-88c4-714a3545bcf9';
    const keyId = 'eb181eb6-be94-4dd8-b074-a80125ede9b8';
    const modalTitle = 'Este es el titulo';
    const modalSubtitle = 'Este es el Subtitulo o descripcion del modal';
    // const lockId = '82ce8d27-446e-4ebf-90af-5112b5780467';
    const device_id = '06580653-5824-426f-9f93-7a7b4f8e37ee';

    const routesObj = [
        // {
        //     pageName: 'lock administration',
        //     url: `/mobile/locks/administration?device_id=${device_id}&access_token=${accessToken}`,
        // },
        {
            pageName: 'Site List',
            url: `/mobile/sites?access_token=${accessToken}`,
        },
        {
            pageName: 'Key List',
            url: `/mobile/keys?lock_id=${validityData?.deviceId}&access_token=${accessToken}`,
        },
        {
            pageName: 'Key Detail',
            url: `/mobile/keys/detail?key_id=${keyId}&access_token=${accessToken}`,
        },
        {
            pageName: 'Key Creation',
            url: `/mobile/keys/creation?access_token=${accessToken}`,
        },
        {
            pageName: 'Passcode List',
            url: `/mobile/passcodes?access_token=${accessToken}&lock_id=${validityData?.deviceId}`,
        },
        {
            pageName: 'Passcode Details',
            url: `/mobile/passcodes/detail?access_token=${accessToken}&lock_id=${validityData?.deviceId}&passcode_id=${passcodeId}`,
        },
        {
            pageName: 'Passcode Creation',
            url: `/mobile/passcodes/creation?access_token=${accessToken}&lock_id=${validityData?.deviceId}`,
        },
        {
            pageName: 'Rfid Card List',
            url: `/mobile/rfid-cards?access_token=${accessToken}&device_id=${validityData?.deviceId}`,
        },
        {
            pageName: 'Rfid Card Details',
            url: `/mobile/rfid-cards/detail?access_token=${accessToken}&rfid_card_id=${rfidCardId}`,
        },
        {
            pageName: 'Rfid Card Creation Introduction',
            url: `/mobile/rfid-cards/creation/introduction?lock_id=${validityData?.deviceId}`,
        },
        {
            pageName: 'Rfid Card Creation Owner',
            url: `/mobile/rfid-cards/creation/owner?access_token=${accessToken}&lock_id=${validityData?.deviceId}`,
        },
        {
            pageName: 'Rfid Card Creation Validity',
            url: `/mobile/rfid-cards/creation/validity?access_token=${accessToken}&device_id=${
                validityData?.deviceId
            }&label=${validityData?.label}&card_id=${validityData?.cardId}&card_number=${
                validityData?.cardNumber || ''
            }&cyclic_rfid_config_enabled=${validityData?.cyclicRfidConfigEnabled}&overwritten=${
                validityData?.overwritten
            }`,
        },
        {
            pageName: 'Rfid Card Creation Success',
            url: `/mobile/rfid-cards/creation/success?lock_name=${successData.lock_name}&label=${successData.label}&nbf_date=${successData.nbf_date}&expires_at_date=${successData.expires_at_date}&cyclic_rfid_config_enabled=${successData.cyclic_rfid_config_enabled}&nbf_time=${successData.nbf_time}&expires_at_time=${successData.expires_at_time}&dow=${successData.dow}`,
        },
        {
            pageName: 'Rfid Card Creation Warning',
            url: `/mobile/rfid-cards/creation/warning`,
        },
        {
            pageName: 'Rfid Card Creation Info',
            url: `/mobile/rfid-cards/creation/info`,
        },
        {
            pageName: 'Rfid Card delete error',
            url: `/mobile/rfid-cards/delete/error`,
        },
        {
            pageName: 'Info Modal',
            url: `/mobile/modal?title=${modalTitle}&subtitle=${modalSubtitle}`,
        },
    ];

    /* Listeners */
    useEffect(() => {
        if (accessToken === '') {
            console.log('NO ACCESS TOKEN');
        }
        setRoutes(routesObj);
    }, []);

    /* View Events */
    const onGoPressed = (url: string) => {
        navigate(url);
    };

    const onSearch = (input: any) => {
        setRoutes(routesObj.filter((r) => r.pageName.toLowerCase().search(input.target.value.toLowerCase()) >= 0));
    };

    /* Private Methods */
    //Ex. const increaseCount = () => {}

    // Return state and events
    return { routes, onGoPressed, onSearch };
};
